import React from 'react';
import mend from './pngs/mend.png'

const Mend = () => {
  return (
    <img  src={mend} alt="mend-logo" width={35} height={35}/>
  )
}

export default Mend;
