import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStylesSidebar = makeStyles({
  svg: {
    width: 'auto',
    height: 60,
    'margin-left': '-4px',
  },
  path: {
    fill: '#F6F4E8',
  },
});
const useStylesMain = makeStyles({
  svg: {
    width: 'auto',
    height: 180,
    'margin-left': '-4px',
  },
  path: {
    fill: "currentcolor",
  },
});
const LogoFull = ({isMain}:{isMain?:boolean}) => {
  const sidebarClasses = useStylesSidebar();
  const mainClasses = useStylesMain();
  let classes = sidebarClasses;
  if (isMain) {
    classes = mainClasses;
  }
  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 630"
    >
      <path className={classes.path} fillRule="evenodd" clipRule="evenodd" d="M89.9048 188H144.551C152.579 188 159.085 194.505 159.085 202.534V257.18H89.9048V188ZM159.085 257.18H213.731C221.759 257.18 228.265 263.685 228.265 271.713V381.006C228.265 389.034 221.759 395.54 213.731 395.54H89.9049V340.893C89.9049 332.865 96.4101 326.36 104.438 326.36H159.085V257.18Z" />
      <path className={classes.path} d="M406.921 399.909C365.845 399.909 328.264 378.137 328.264 329.659H353.609C353.609 365.365 383.615 376.686 408.086 376.686C438.383 376.686 453.532 362.462 453.532 343.883C453.532 325.305 437.801 317.467 406.921 305.856L392.355 300.631C354.775 287.278 334.673 275.086 334.673 244.025C334.673 210.933 360.892 188 404.882 188C446.54 188 478.877 207.739 478.877 256.507H453.532C453.532 223.125 429.935 211.223 404.59 211.223C376.915 211.223 360.018 224.286 360.018 242.283C360.018 261.152 373.419 268.119 402.842 278.569L416.534 283.504C451.784 296.277 480.333 309.049 480.333 342.722C480.333 374.944 455.863 399.909 406.921 399.909Z" />
      <path className={classes.path} d="M522.811 395.554V190.903H546.117V309.049H571.753L616.325 246.347H635.552V257.378L592.437 318.048L639.631 384.523V395.554H618.656L571.753 329.369H546.117V395.554H522.811Z" />
      <path className={classes.path} d="M698.293 442V433.291L714.898 395.554L658.673 257.378V246.347H680.522L726.26 364.203L771.123 246.347H792.389V257.378L717.811 442H698.293Z" />
      <path className={classes.path} d="M823.235 395.554V246.347H835.47L843.919 266.087C854.115 254.766 871.885 242.864 892.86 242.864H896.647V265.506H893.443C873.633 265.506 858.193 272.473 846.54 286.407V395.554H823.235Z" />
      <path className={classes.path} d="M966.972 399.038C940.461 399.038 917.447 385.104 917.447 355.785C917.447 325.305 938.422 308.178 979.498 308.178H1017.08V293.374C1017.08 275.957 1006.01 264.055 982.411 264.055C959.397 264.055 948.036 275.957 948.036 291.632H925.313C925.313 263.765 943.666 242.864 982.411 242.864C1021.45 242.864 1040.38 263.765 1040.38 291.922V395.554H1026.4L1017.66 376.686C1008.63 386.846 990.277 399.038 966.972 399.038ZM971.633 377.847C991.442 377.847 1007.46 366.526 1017.08 356.946V327.047H979.498C951.823 327.047 940.753 337.787 940.753 353.173C940.753 370.299 954.154 377.847 971.633 377.847Z" />
      <path className={classes.path} d="M1086.96 395.554V190.903H1110.26V395.554H1086.96Z" fill="#F6F4E8"/>
    </svg>
  );
};

export default LogoFull;
