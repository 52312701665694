import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import {
  HomePageSearchBar,
} from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFull from "../Root/LogoFull";

// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

// import PngIcon from './PngIcon';

const useStyles = makeStyles(_theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    // boxShadow: theme.shadows[1],
    padding: '8px 0',
    // borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

import Confluence from './assets/Confluence';
import JFrog from './assets/JFrog';
import Mend from './assets/Mend';
import Slack from './assets/Slack';
import { GitHubIcon } from '@backstage/core-components';

const tools = [
  {
    url: 'https://skyralworkspace.slack.com/archives/C05V9L8M155',
    label: '#ask-core-ee',
    icon: <Slack />,
  },
  {
    url: 'https://github.com/skyral-group',
    label: 'skyral-group',
    icon: <GitHubIcon />,
  },
  {
    url: 'https://github.com/skyral-defence',
    label: 'skyral-defence',
    icon: <GitHubIcon />,
  },
  {
    url: 'https://skyralgroup.jfrog.io',
    label: 'JFrog',
    icon: <JFrog />,
  },
  {
    url: 'https://app-eu.whitesourcesoftware.com/Wss/WSS.html#!login',
    label: 'Mend',
    icon: <Mend />,
  },
  {
    url: 'https://skyral.atlassian.net/wiki/spaces/SGCoreEng/overview',
    label: 'Confluence',
    icon: <Confluence />,
  }
]

export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();

  // We currently do not use configuration values to edit our featured article and tools.
  // Resolution of this work is scheduled for https://skyral.atlassian.net/browse/EVE-2950.
  // For this to be in use, the hardcoded values above should be removed.
  //
  // const featured_article = config.getConfig("homepage.featuredArticle");
  // const tools = config.getConfigArray('homepage.quickLinks').map(r => {
  //   return {
  //     'url': r.getString('url'),
  //     'icon': <PngIcon imageUrl={r.get('iconUrl')} label={r.get('label')} />,
  //     'label': r.getString('label')
  //   }
  // })

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFull isMain={true} />}
            />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={6} style={{height: 'min-content'}}>
                <Grid item xs={12} md={12}>
                  <HomePageStarredEntities />
                </Grid>
              </Grid>

              <Grid container item xs={6} style={{height: 'min-content'}}>
                <Grid item xs={12} md={12} style={{fontSize: '0.8em'}}>
                  <HomePageToolkit
                    tools={tools}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
