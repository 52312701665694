import {
  createBaseThemeOptions,
  createUnifiedTheme,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  genPageTheme,
} from '@backstage/theme';

import { skyralShapes } from './skyralShapes';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#172B4D',
  };
});

export const skyralLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#343b58',
      },
      secondary: {
        main: '#565a6e',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#231F28',
        indicator: '#CBA6FF',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
  }),
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#20004C', '#CBA6FF'], shape: skyralShapes.grid1 }),
    documentation: genPageTheme({
      colors: ['#20004C', '#CBA6FF'],
      shape: skyralShapes.grid1,
    }),
    tooling: genPageTheme({
      colors: ['#CBA6FF', '#343b58'],
      shape: skyralShapes.grid3,
    }),
    service: genPageTheme({
      colors: ['#CBA6FF', '#343b58'],
      shape: skyralShapes.grid3,
    }),
    website: genPageTheme({
      colors: ['#20004C', '#343b58'],
      shape: skyralShapes.grid2,
    }),
    library: genPageTheme({
      colors: ['#DABFFF', '#343b58'],
      shape: skyralShapes.grid2,
    }),
    other: genPageTheme({ colors: ['#20004C', '#343b58'], shape: skyralShapes.grid3 }),
    app: genPageTheme({ colors: ['#CBA6FF', '#343b58'], shape: skyralShapes.grid2 }),
    apis: genPageTheme({ colors: ['#343b58', '#DABFFF'], shape: skyralShapes.grid3 }),
    api: genPageTheme({ colors: ['#343b58', '#DABFFF'], shape: skyralShapes.grid3 }),
    card: genPageTheme({ colors: ['#343b58', '#DABFFF'], shape: skyralShapes.grid3 }),
  },
});

// Dark Theme
export const skyralDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      // primary: {
      //   main: '#343b58',
      // },
      // secondary: {
      //   main: '#565a6e',
     // },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      // infoBackground: '#343b58',
      navigation: {
        background: '#231F28',
        indicator: '#CBA6FF',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
  }),
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#20004C', '#CBA6FF'], shape: skyralShapes.grid1 }),
    documentation: genPageTheme({
      colors: ['#20004C', '#CBA6FF'],
      shape: skyralShapes.grid1,
    }),
    tooling: genPageTheme({
      colors: ['#CBA6FF', '#343b58'],
      shape: skyralShapes.grid3,
    }),
    service: genPageTheme({
      colors: ['#CBA6FF', '#343b58'],
      shape: skyralShapes.grid3,
    }),
    website: genPageTheme({
      colors: ['#20004C', '#343b58'],
      shape: skyralShapes.grid2,
    }),
    library: genPageTheme({
      colors: ['#DABFFF', '#343b58'],
      shape: skyralShapes.grid2,
    }),
    other: genPageTheme({ colors: ['#20004C', '#343b58'], shape: skyralShapes.grid3 }),
    app: genPageTheme({ colors: ['#CBA6FF', '#343b58'], shape: skyralShapes.grid2 }),
    apis: genPageTheme({ colors: ['#343b58', '#DABFFF'], shape: skyralShapes.grid3 }),
    api: genPageTheme({ colors: ['#343b58', '#DABFFF'], shape: skyralShapes.grid3 }),
    card: genPageTheme({ colors: ['#343b58', '#DABFFF'], shape: skyralShapes.grid3 }),
  },
});
