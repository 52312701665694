import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 60,
    'margin-left': '-4px',
  },
  path: {
    fill: "currentcolor",
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 630"
    >
      <path className={classes.path} fillRule="evenodd" clipRule="evenodd" d="M89.9048 188H144.551C152.579 188 159.085 194.505 159.085 202.534V257.18H89.9048V188ZM159.085 257.18H213.731C221.759 257.18 228.265 263.685 228.265 271.713V381.006C228.265 389.034 221.759 395.54 213.731 395.54H89.9049V340.893C89.9049 332.865 96.4101 326.36 104.438 326.36H159.085V257.18Z" fill="#F6F4E8"/>
    </svg>
  );
};

export default LogoFull;
